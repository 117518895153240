.container {
  background-color: #f8f8f8;
  height: 100%;
  font-family: PingFangSC-Regular;
}
.container .content {
  background-color: #f8f8f8;
  padding-bottom: 1rem;
}
.user_msg {
  margin-top: 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  background-color: #ffffff;
  font-family: PingFang SC;
  padding: 0.15rem 0;
  padding-bottom: 0;
}
.user_msg .msg {
  padding: 0 0.2rem 0 0.2rem;
  display: flex;
  justify-content: center;
}
.user_msg .msg .msg-top {
  display: flex;
  align-items: center;
}
.user_msg .msg .msg-top .msg_left {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user_msg .msg .msg-top .msg_left .img {
  width: 1.2rem;
  height: 1.2rem;
}
.user_msg .msg .msg-top .msg_left .img img {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}
.user_msg .msg .msg-top .msg_left .name {
  margin-top: 0.08rem;
  text-align: center;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.user_msg .msg .msg-top .msg_right {
  margin-left: 0.76rem;
}
.user_msg .msg .msg-top .msg_right .time {
  color: #333333;
  font-size: 0.24rem;
  font-weight: 500;
}
.user_msg .msg .msg-top .msg_right .VIP {
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
}
.user_msg .msg .msg-top .msg_right .VIP .img_vip img {
  width: 0.252399rem;
  height: 0.2218rem;
}
.user_msg .msg .msg-top .msg_right .VIP .vip_number {
  margin-left: 0.1rem;
  font-size: 0.28rem;
  color: #efba0d;
}
.user_msg .msg .msg-top .msg_right .type {
  margin-top: 0.12rem;
  color: #efba0d;
  background-color: #fffae8;
  padding: 0.06rem 0 0.04rem 0.2rem;
  font-size: 0.24rem;
  font-weight: 500;
  border-radius: 0.04rem;
}
.user_msg .shuju {
  border-top: 0.01rem solid rgba(211, 217, 222, 0.49);
  padding: 0.3rem 0;
  margin: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.user_msg .shuju .list1 {
  display: flex;
  align-items: center;
}
.user_msg .shuju .list1 .top_left {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  border-right: 0.02rem solid rgba(211, 217, 222, 0.5);
}
.user_msg .shuju .list1 .top_right {
  padding: 0 0.4rem;
  text-align: center;
}
.user_msg .shuju .list1 .top_left {
  text-align: center;
}
.user_msg .shuju .list1 .txt {
  color: #6f7a8b;
  font-size: 0.24rem;
}
.user_msg .shuju .list1 .txt1 {
  color: #f5465d;
  font-size: 0.24rem;
}
.user_msg .shuju .list1 .left_num {
  font-family: PingFangSC-Medium;
  color: #333333;
  font-size: 0.32rem;
  font-weight: bold;
}
.user_msg .list {
  margin: 0 0.2rem;
  padding-bottom: 0.5rem;
}
.user_msg .list .list_item {
  font-family: PingFangSC-Medium;
  padding: 0.18rem 0;
  padding-left: 0.58rem;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.28rem;
  color: #333333;
  font-weight: 600;
}
.user_msg .list .list-right {
  text-align: right;
}
.user_msg .list .list_item:last-child {
  padding-bottom: 0.5rem;
}
.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 0;
}
.bottom .button {
  margin: 0.14rem 0.3rem 0.14rem 0.28rem;
  width: 6.9rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background-color: #fad538;
  color: #333333;
  font-size: 0.32rem;
  text-align: center;
  border-radius: 0.12rem;
  font-weight: 500;
}
.tan1 {
  width: 5.6rem;
  height: 3.4rem;
}
.tan1 .tan_no_txt {
  padding: 0.69rem 0.82rem 0 0.6rem;
  color: #333333;
  font-size: 0.32rem;
  text-align: center;
  font-weight: 500;
}
.tan1 .tan_no_txt1 {
  padding: 0.69rem 0.82rem 0 0.72rem;
  font-size: 0.24rem;
  text-align: left;
  font-weight: 500;
  color: #333333;
}
.tan1 .tan_no_txt2 {
  margin-top: 0.05rem;
  color: #333333;
  font-size: 0.16rem;
  transform: scale(0.75);
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  text-align: left;
}
.tan1 .tan_no_txt2 .right {
  color: #f5465d;
}
.tan1 .input {
  margin-top: 0.08rem;
  padding: 0 0.7rem 0 0.72rem;
  color: #333333;
  font-weight: bold;
}
.tan1 .input input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #d3d9de;
  padding: 0.04rem 0;
  font-size: 0.32rem;
}
.tan1 .tan_no_button {
  margin: 0.47rem 1.2rem 0 1.32rem;
  padding: 0.1rem 0.36rem 0.08rem 0.36rem;
  font-size: 0.28rem;
  color: #ffffff;
  background-color: #fad538;
  text-align: center;
  border-radius: 0.08rem;
}
